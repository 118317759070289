<template>
	<div
		class="wrap__content"
		v-bind:class="{ 'wrap__content--preloader': !applications && !permissionsList && !moduleList }"
	>
		<v-preloader
			v-if="!applications && !permissionsList && !moduleList"
			:message="errorMessage"
		></v-preloader>

		<div class="container" v-if="applications && permissionsList && moduleList">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['ReferenceManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__page--back">
										<a @click="$router.go(-1)">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.125 5.25l-5.25 5.25 5.25 5.25" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</a>
									</div>
									<div class="content__title--element">
										<div class="content__title--text">Просмотр справочника</div>
										<div class="content__title--subtext">
											{{ applications.name }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="form__line">
									<div class="form__line--left">
										<div class="form__line--title">Название</div>
									</div>
									<div class="form__line--right">
										<div class="row">
											<div class="col-xl-5">
												<div class="form__line--input">
													<input
														type="text"
														class="input-linck"
														disabled
														:value="applications.name"
														:placeholder="$t('headers.name')"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="form__line">
									<div class="form__line--left">
										<div class="form__line--title">Столбцы в таблице</div>
									</div>
									<div class="form__line--right">
										<div
											class="form__line form__line--children"
											v-for="(item, index) in fildsList"
											:key="index"
										>
											<div class="row align-items-center">
												<div class="col-xl-5">
													<div class="form__line--input">
														<input
															type="text"
															class="input-linck"
															:name="'columns[' + index + ']'"
															v-model="fildsList[index].name"
															:placeholder="$t('placeholder.adds_field')"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="form__line">
									<div class="form__line--left">
										<div class="form__line--title">{{ $t('labels.use') }}</div>
									</div>
									<div class="form__line--right">
										<div class="row">
											<div class="col-xl-5">
												<div class="form__checkbox--block">
													<label
														class="form__checkbox--line"
														v-for="element in moduleSelect"
														:key="element"
													>
														<input
															type="checkbox"
															name="modules[]"
															:value="element.value"
															disabled
															:checked="element.checked"
														/>
														<div class="checkbox__item">
															<span class="form__checkbox--text">
																{{ element.name }}
															</span>
															<span class="form__checkbox--check"></span>
														</div>
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="form__line">
									<div class="form__line--left"></div>
									<div class="form__line--right">
										<div class="permission__block">
											<div class="row">
												<div class="col-xl-5">
													<div class="permission__block--add">
														<a> Разрешения </a>
													</div>
													<div class="permission__block--list">
														<a
															class="permission__block--item"
															v-for="(permission, key) in permissions"
															:key="key"
														>
															{{ permission.description }}
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="form__line">
									<div class="form__line--left"></div>
									<div class="form__line--right">
										<div
											class="form__btn--line"
											v-if="$store.getters.userPermission(['change_catalog'])"
										>
											<div class="input__row">
												<a
													class="input-btn form__line--btn"
													v-if="(applications.status != 'active', applications.uneditable != 1)"
													@click="activeStatus(applications.id)"
												>
													Применить справочник
												</a>
												<a
													class="input-btn form__line-error form__line--btn"
													v-if="(applications.status != 'deleted', applications.uneditable != 1)"
													@click="$modal.show('modal_delete')"
												>
													Заблокировать справочник
												</a>
											</div>
										</div>

										<modal
											class="modal__block modal__block--middle modal__block--delete"
											name="modal_delete"
											:width="550"
											:min-width="550"
											height="auto"
										>
											<div class="modal__block--wrapper">
												<div class="modal__block--close" @click="$modal.hide('modal_delete')">
													<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</div>
												<div class="modal__wrapper">
													<div class="modal__header">
														<div class="modal__title">
															Вы уверены что хотите заблокировать справочник<br />
															<strong>
																{{ applications.name }}
															</strong>
														</div>
													</div>

													<div class="modal__content">
														<div class="modal__form">
															<div class="block__row">
																<div class="block__row--left">
																	<a class="block__item" @click="blockStatus(applications.id)">
																		<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.024 12.976l4.952-4.952m0 4.952L8.024 8.024M10.5 19.25c4.813 0 8.75-3.938 8.75-8.75 0-4.813-3.938-8.75-8.75-8.75-4.813 0-8.75 3.938-8.75 8.75 0 4.813 3.938 8.75 8.75 8.75z" stroke="#DF4242" stroke-linecap="round" stroke-linejoin="round"/></svg>
																		{{ $t('button.deactivate') }}
																	</a>
																</div>
																<div class="block__row--right">
																	<a
																		class="block__item block__item--blue"
																		@click="$modal.hide('modal_delete')"
																	>
																		{{ $t('button.cancel') }}
																	</a>
																</div>
															</div>
															<div
																class="form__block--line form__messages"
																v-if="message.status"
																:class="{
																	'form__messages--error': message.status != 200,
																	'form__messages--success': message.status == 200,
																}"
															>
																<label class="form__block--input">
																	<span class="form__block--title">
																		{{ message.text }}
																	</span>
																</label>
															</div>
														</div>
													</div>
												</div>
											</div>
										</modal>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { api } from '@/boot/axios'

export default {
	components: {
		Multiselect,
	},
	data() {
		return {
			applications: null,
			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			fildsNew: '',
			fildsList: [],
			fildsIndex: 0,

			moduleList: null,
			moduleSelect: null,

			permissionsList: null,
			permissionsSelect: [],
			permissions: [],
		}
	},
	methods: {
		activeStatus(id) {
			api
				.post('catalog/' + id + '/activate', {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message

						this.$router.push({ path: '/' + this.$i18n.locale + '/account/references' })
					}
				})
		},
		blockStatus(id) {
			api
				.post('catalog/' + id + '/deactivate', {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message

						this.blockElemet = null
						this.$modal.hide('modal_delete')

						this.$router.push({ path: '/' + this.$i18n.locale + '/account/references' })
					}
				})
		},

		apiGetRoleList() {
			api
				.get('user/role/list')
				.then((response) => {
					if (response.data) {
						this.permissionsList = response.data
						this.apiGetModuleList()
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
		apiGetCatalog() {
			api
				.get('catalog/' + this.$route.params.id)
				.then((response) => {
					if (response.data) {
						this.applications = response.data
						this.fildsList = response.data.columns
						this.moduleSelect = this.moduleList.filter((element) => {
							let module = element
							this.applications.modules.includes(element.value)
								? (module.checked = true)
								: (module.checked = false)
							return module
						})
						this.applications.roles.find((element) => {
							this.permissionsSelect.push(element.id)
						})
						this.permissions = this.permissionsList.filter((element) => {
							return this.permissionsSelect.includes(element.id)
						})
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
		apiGetModuleList() {
			api.get('catalog/module/list').then((response) => {
				if (response.data) {
					this.moduleList = response.data
					this.apiGetCatalog()
				}
			})
		},
	},
	beforeMount() {
		this.apiGetRoleList()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
}
</script>

<style scoped></style>
